import { useApiClient } from "../../../../providers/api-client";
import { useStyles } from "../../../../providers/styles/use-styles";
import Button from "../../../components/Button/Button";
import { Game, Question, QuestionType } from "../../../models/game.entity";
import styles from "./questions.module.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface Props {
  token: string;
  game: Game;
  questionsNumber: number;
  onGameFinished: () => void;
}

const Questions = ({ token, game, questionsNumber, onGameFinished }: Props) => {
  const client = useApiClient();
  const { getStyles } = useStyles();

  const [isLoading, setLoading] = useState(false);

  const [questions, setQuestions] = useState<Question[]>();
  const [position, setPosition] = useState<number>(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [openAnswer, setOpenAnswer] = useState<string>();

  useEffect(() => {
    setQuestions(game.questions);
    setPosition(game.position);
  }, []);

  async function submitResult() {
    setLoading(true);

    try {
      await client.postQuestionAnswer(
        token,
        questions!![position].id,
        selectedAnswer,
        openAnswer
      );

      if (position < questionsNumber - 1) {
        setPosition(position + 1);
        setSelectedAnswer(undefined);
        setOpenAnswer("");
      } else {
        onGameFinished();
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
        position: "top-center",
        hideProgressBar: true,
        icon: false,
        closeButton: false,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.main}>
      {questions && (
        <>
          <h3
            className={styles.title}
            style={getStyles(["txt_label_primary_base"])}
          >
            {" "}
            {position + 1} / {questionsNumber}
          </h3>

          <div
            className={styles.question}
            style={getStyles(["txt_card_primary_base", "bg_card_primary_base"])}
          >
            {questions[position].text}
          </div>

          <div className={styles.answersWrapper}>
            {questions[position].type === QuestionType.singleChoice && (
              <>
                {questions[position].answers.map((answer) => (
                  <div
                    className={styles.answer}
                    onClick={() => !isLoading && setSelectedAnswer(answer.id)}
                    style={{
                      backgroundColor:
                        answer.id === selectedAnswer ? "white" : "transparent",
                      color: answer.id === selectedAnswer ? "#2059FF" : "white",
                      opacity: isLoading ? 0.7 : 1,
                      ...getStyles(
                        answer.id === selectedAnswer
                          ? [
                            "txt_chips_primary_selected",
                            "bg_chips_primary_selected",
                          ]
                          : [
                            "txt_chips_primary_base",
                            "border_chips_primary_base",
                          ]
                      ),
                    }}
                  >
                    {answer.text}
                  </div>
                ))}
              </>
            )}

            {questions[position].type === QuestionType.open && (
              <input
                className={styles.input}
                style={getStyles([
                  "txt_chips_primary_base",
                  "border_chips_primary_base",
                ])}
                placeholder="Введите ваш ответ"
                value={openAnswer}
                disabled={isLoading}
                onChange={(e) => setOpenAnswer(e.target.value)}
                maxLength={40}
              />
            )}
          </div>

          <div className={styles.spacer} />

          <Button
            title={
              isLoading
                ? "Загрузка"
                : position < questionsNumber - 1
                  ? "Следующий вопрос"
                  : "Завершить"
            }
            onClick={submitResult}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default Questions;
